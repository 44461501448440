import * as React from 'react';

import DataTable from "../components/DataTable";
import {Button, Switch} from "@mui/material";
import {invalidateToken, toggleScope} from "../../util/requests";

export default function Tokens({activeUser}) {
    const [updateCounter, setUpdateCounter] = React.useState(0);

    const titles = [
        "ID",
        "Key",
        "Token",
        "Scopes",
        "Tenant",
        "Actions",
    ];

    const actions = (tokenId) => {
        return (
            <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                    invalidateToken(activeUser, tokenId, () => {
                        setUpdateCounter(updateCounter + 1);
                    }, (error) => {
                        console.error(error);
                    })
                }}
            >
                Invalidate
            </Button>
        );
    }

    const scopes = (tokenId, scopeStrings) => {
        return (
            <>
                {["chat", "manage", "admin"].map(scopeString => {
                    return (
                        <>
                            {scopeString}
                            <Switch
                                checked={(scopeStrings || []).includes(scopeString)}
                                onChange={() => {
                                    toggleScope(activeUser, tokenId, scopeString, (response) => {
                                        setUpdateCounter(updateCounter + 1);
                                    }, (error) => {
                                        console.error(error);
                                    })
                                }}
                            />
                        </>
                    );
                })}
            </>
        );
    }

    const valueCallback = (title, record) => {
        switch (title) {
            case "ID":
                return record.id;
            case "Key":
                return record.key;
            case "Token":
                return <span style={{fontSize: '10px', overflow: 'hidden', maxWidth: '150px', display: 'inline-block'}}>{record.token}</span>;
            case "Scopes":
                return scopes(record.id, record.scopes);
            case "Tenant":
                return record.tenant_name;
            case "Actions":
                return actions(record.id);
            default:
                return "...";
        }
    };

    return (
        <>
            <DataTable
                key={updateCounter}
                modelName={"Token"}
                model={"token"}
                userId={activeUser}
                titles={titles}
                valueCallback={valueCallback}
                allowsCreate={true}
                simpleCreateFields={[
                    {
                        "name": "Key",
                        "key": "key",
                    },
                    {
                        "name": "Tenant ID",
                        "key": "tenant_id",
                        "type": "number",
                    },
                ]}
            />
        </>
    );
}
