import * as React from 'react';

import DataTable from "../components/DataTable";
import {FormControlLabel, Switch} from "@mui/material";


export default function Models({activeTenant, activeModel, setActiveModel}) {
    let titles = [
        "ID",
        "Key",
        "Base Model",
        "Language",
        "Actions",
    ];
    let actions = (model_id) => {
        if (model_id === activeModel) {
            return <FormControlLabel control={
                <Switch checked={true}/>
            } label={"Active"}/>;
        } else {
            return <FormControlLabel control={
                <Switch checked={false} onChange={() => {
                    setActiveModel(model_id)
                }}/>
            } label={"Set Active"}/>;
        }
    }
    let valueCallback = (title, record) => {
        switch (title) {
            case "ID":
                return record.id;
            case "Key":
                return record.key;
            case "Base Model":
                return record.base_model;
            case "Language":
                return record.language;
            case "Actions":
                return actions(record.id);
            default:
                return "...";
        }
    };

    return (
        <>
            <DataTable
                modelName={"Model"}
                tenantId={activeTenant}
                model={"model"}
                titles={titles}
                valueCallback={valueCallback}
                allowsCreate={true}
                simpleCreateFields={[
                    {
                        "name": "Key (gpt-4)",
                        "key": "key",
                    },
                    {
                        "name": "Base Model (gpt-4)",
                        "key": "base_model",
                    },
                    {
                        "name": "Language (de)",
                        "key": "language",
                    },
                    {
                        "name": "Model Type (conversation)",
                        "key": "model_type",
                    },
                ]}
            />
        </>
    );
}
