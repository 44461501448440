import * as React from 'react';

import DataTable from "../components/DataTable";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import IconButton from "@mui/material/IconButton";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';

import {updateCoreFacts} from "../../util/requests";


export default function KnowledgeBases({activeTenant, setActiveArea, setActiveKnowledgeBase}) {
    const [updateCounter, setUpdateCounter] = React.useState(0);

    const updateKnowledgeBaseCore = (tenantId, knowledgeBaseId, newCoreFacts) => {
        updateCoreFacts(tenantId, knowledgeBaseId, newCoreFacts, (response) => {
            setUpdateCounter(updateCounter + 1);
        }, (error) => {
            console.error(error);
        });
    }

    let titles = [
        "ID",
        "Name",
        "Description",
        "Language",
        "Core Facts",
        "Actions",
    ];
    let actions = (knowledge_base_id) => {
        return (
            <>
                <IconButton variant="contained" onClick={() => {
                    setActiveKnowledgeBase(knowledge_base_id);
                    setActiveArea("Knowledge Base Facts");
                }}>
                    <FactCheckIcon/>
                </IconButton>
            </>
        );
    }
    let valueCallback = (title, record) => {
        switch (title) {
            case "ID":
                return record.id;
            case "Name":
                return record.name;
            case "Description":
                return record.description;
            case "Language":
                return record.language;
            case "Core Facts":
                return (
                    <>
                        <IconButton variant="contained" onClick={() => {
                            console.log('clicking');
                            updateKnowledgeBaseCore(record.tenant_id, record.id, !record.has_core_facts)
                        }}>
                            {record.has_core_facts ? <TaskAltIcon/> : <PanoramaFishEyeIcon/>}
                        </IconButton>
                    </>
                );
            case "Actions":
                return actions(record.id);
            default:
                return "...";
        }
    };

    return (
        <>
            <DataTable
                key={updateCounter}
                modelName={"Knowledge Base"}
                tenantId={activeTenant}
                model={"knowledge_base"}
                titles={titles}
                valueCallback={valueCallback}
                allowsCreate={true}
                simpleCreateFields={[
                    {
                        "name": "Name",
                        "key": "name",
                    },
                    {
                        "name": "Description",
                        "key": "description",
                    },
                    {
                        "name": "Language (de)",
                        "key": "language",
                    },
                ]}
            />
        </>
    );
}
