import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import * as React from "react";
import {CircularProgress} from "@mui/material";
import SimpleNewEntryForm from "../sub_views/SimpleNewEntryForm";
import {getRecords, updateRecord} from "../../util/requests";

export default function DataTable({modelName, model, tenantId, userId, titles, valueCallback, allowsCreate, simpleCreateFields, customCreateForm}) {
    const [reloading, setReloading] = React.useState(false);
    const [records, setRecords] = React.useState([]);

    React.useEffect(() => {
        getRecords(tenantId, userId, model, (records) => {
            setRecords(records.data);
        }, (error) => {
            console.error(error);
        })
    }, [model, userId, tenantId, reloading]);

    let tableData = <></>;
    if (reloading || records === null) {
        tableData = (
            <TableRow>
                <TableCell colSpan={titles.length} align={"center"}>
                    <CircularProgress />
                </TableCell>
            </TableRow>
            );
    } else if (records.length === 0) {
        tableData = (
            <TableRow>
                <TableCell colSpan={titles.length} align={"center"}>
                    ...no data...
                </TableCell>
            </TableRow>
        );
    } else {
        tableData = [...records].reverse().map((record) => (
            <TableRow key={record.id}>
                {titles.map((title) => (
                    <TableCell key={title}>{valueCallback(title, record)}</TableCell>
                ))}
            </TableRow>
        ));
    }

    let createForm = <></>;
    if (allowsCreate) {
        if (customCreateForm != null) {
            createForm = customCreateForm;
        } else {
            createForm = (
                <SimpleNewEntryForm
                    modelName={modelName}
                    fields={(simpleCreateFields || [])}
                    saveCallback={(data) => {
                        setReloading(true);
                        updateRecord(data, tenantId, userId, model, (response) => {
                            setReloading(false);
                        }, (error) => {
                            setReloading(false);
                            console.error(error);
                        })
                    }}
                />
            );
        }
    }

    return (
        <>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {titles.map((title) => (
                            <TableCell key={title}>{title}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData}
                </TableBody>
            </Table>
            {createForm}
        </>
    );
}
