import * as React from 'react';

import DataTable from "../components/DataTable";
import Button from "@mui/material/Button";
import { MuiFileInput } from 'mui-file-input'
import {Alert} from "@mui/material";
import {downloadExcelFacts, uploadExcelFacts} from "../../util/requests";


export default function KnowledgeBaseFacts({activeTenant, activeKnowledgeBase}) {
    const [file, setFile] = React.useState(null);
    const [lastAlert, setLastAlert] = React.useState(<></>);

    let titles = [
        "ID",
        "Context",
        "Triggers",
        "Fact",
        "Language",
    ];
    let valueCallback = (title, record) => {
        switch (title) {
            case "ID":
                return record.id;
            case "Context":
                return record.context;
            case "Triggers":
                return (record.triggers || []).join(', ');
            case "Fact":
                return record.fact;
            case "Language":
                return record.language;
            default:
                return "...";
        }
    };

    const handleFileChange = (newFile) => {
        setFile(newFile);
    };
    const handleClickDownload = async () => {
        downloadExcelFacts(activeTenant, activeKnowledgeBase, (error) => {
            console.error('Error downloading file:', error);
        })
    };
    const handleClickUpload = () => {
        uploadExcelFacts(activeTenant, activeKnowledgeBase, file, (response) => {
            setLastAlert(
                <Alert severity="success">
                    Upload successful!
                </Alert>
            );
        }, (error) => {
            setLastAlert(
                <Alert severity="error">
                    Upload not successful! {error.response.data.message} {error.response.data.detail}
                </Alert>
            );
        });
    };
    const uploadDownloadExcel = (
      <>
          <div>
              <MuiFileInput value={file} onChange={handleFileChange} sx={{ mt: 2, mb: 0 }} />
              <br/>
              <Button variant="contained" onClick={handleClickDownload} sx={{ mt: 2, mb: 0 }}>
                  Download Excel
              </Button>
              &nbsp;
              <Button variant="contained" onClick={handleClickUpload} sx={{ mt: 2, mb: 0 }}>
                  Upload Excel
              </Button>
              <br/><br/>
          </div>
      </>
    );

    return (
        <>
            {lastAlert}
            {uploadDownloadExcel}
            &nbsp;
            <DataTable
                modelName={"Knowledge Base"}
                tenantId={activeTenant}
                model={`knowledge_bases/${activeKnowledgeBase}/fact`}
                titles={titles}
                valueCallback={valueCallback}
                allowsCreate={false}
            />
        </>
    );
}
