import config from "../Config";
import axios from "axios";

import {getAuthHeader} from "./authentication";

export function getConversationResponse(tenantId, modelId, prompt, conversation, request_uuid, successCallback, errorCallback) {
    const url = `${config.API_BASE_URL}/v1/business/tenants/${tenantId}/models/${modelId}/generate_completion`
    const conversationHistory = [];
    conversation.forEach((conversationItem) => {
        if (conversationItem["request_uuid"] !== request_uuid) {
            conversationHistory.push({"role": "user", "content": conversationItem["prompt"]});
            if (conversationItem["response"] !== null) {
                conversationHistory.push({"role": "assistant", "content": conversationItem["response"]});
            }
        }
    });
    const data = {
        "prompt": prompt,
        "conversation_history": conversationHistory,
    };
    axios
        .post(url, data, getAuthHeader())
        .then((response) => {
            successCallback(response);
        })
        .catch((error) => {
            errorCallback(error);
        });
}
