import * as React from 'react';

import DataTable from "../components/DataTable";

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import {FormControlLabel, Switch} from "@mui/material";

export default function Users({activeUser, setActiveUser}) {
    let titles = [
        "ID",
        "Username",
        "Email",
        "Tenant",
        "Manage",
        "Admin",
        "Actions",
    ];

    let actions = (user_id) => {
        if (user_id === activeUser) {
            return <FormControlLabel control={
                <Switch checked={true}/>
            } label={"Active"}/>;
        } else {
            return <FormControlLabel control={
                <Switch checked={false} onChange={() => {
                    setActiveUser(user_id)
                }}/>
            } label={"Set Active"}/>;
        }
    }

    let valueCallback = (title, record) => {
        switch (title) {
            case "ID":
                return record.id;
            case "Username":
                return record.username;
            case "Email":
                return record.email;
            case "Tenant":
                return record.tenant_name;
            case "Manage":
                return record.is_manager ? <PlaylistAddCheckIcon/> : <></>;
            case "Admin":
                return record.is_admin ? <WarningAmberIcon/> : <></>;
            case "Actions":
                return actions(record.id);
            default:
                return "...";
        }
    };

    return (
        <>
            <DataTable
                modelName={"User"}
                model={"user"}
                titles={titles}
                valueCallback={valueCallback}
                allowsCreate={true}
                simpleCreateFields={[
                    {
                        "name": "Username",
                        "key": "username",
                    },
                    {
                        "name": "Email",
                        "key": "email",
                    },
                    {
                        "name": "Password",
                        "key": "password",
                        "type": "password",
                    },
                    {
                        "name": "Confirm Password",
                        "key": "password_confirmation",
                        "type": "password",
                    },
                    {
                        "name": "Tenant ID",
                        "key": "tenant_id",
                        "type": "number",
                    },
                ]}
            />
        </>
    );
}
