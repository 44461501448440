import * as React from 'react';

import DataTable from "../components/DataTable";
import Button from "@mui/material/Button";


export default function WebsiteConfigPrompts({activeTenant, activeWebsiteConfig}) {
    let titles = [
        "ID",
        "Page",
        "Prompt",
        "Completion",
        "Language",
    ];
    let valueCallback = (title, record) => {
        switch (title) {
            case "ID":
                return record.id;
            case "Page":
                return <a href={record.reference_url}>{record.reference_title}</a>;
            case "Prompt":
                return record.prompt;
            case "Completion":
                return record.completion;
            case "Context":
                return record.context;
            case "Triggers":
                return (record.triggers || []).join(', ');
            case "Fact":
                return record.fact;
            case "Language":
                return record.language;
            default:
                return "...";
        }
    };

    const startSpider = (event) => {
        alert('start');
    };

    const triggerSpiderButton = <>
        <div>
            <Button variant="contained" onClick={startSpider} sx={{ mt: 2, mb: 0 }}>
                Start Spider
            </Button>
            <br/><br/>
        </div>
    </>;

    return (
        <>
            {triggerSpiderButton}
            <DataTable
                modelName={"Website Config"}
                tenantId={activeTenant}
                model={`website_configs/${activeWebsiteConfig}/prompt`}
                titles={titles}
                valueCallback={valueCallback}
                allowsCreate={false}
            />
        </>
    );
}
