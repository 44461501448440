import * as React from 'react';

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import {login} from "../../util/authentication";
import {sessionTenantId, sessionUsername} from "../../util/session_manager";

export default function Login({setActiveArea, successCallback}) {
    const [tenantId, setTenantId] = React.useState(sessionTenantId());
    const [username, setUsername] = React.useState(sessionUsername());
    const [password, setPassword] = React.useState('');
    const [loginStatus, setLoginStatus] = React.useState(<></>);

    return (
        <>
            {loginStatus}
            <TextField
                autoFocus
                margin="dense"
                id="tenantId"
                label="Tenant ID"
                fullWidth
                variant="standard"
                value={tenantId}
                onChange={(ev) => {setTenantId(ev.target.value)}}
            />
            <TextField
                autoFocus
                margin="dense"
                id="username"
                label="Username"
                fullWidth
                variant="standard"
                value={username}
                onChange={(ev) => {setUsername(ev.target.value)}}
            />
            <TextField
                key=""
                autoFocus
                margin="dense"
                id="password"
                label="Password"
                fullWidth
                variant="standard"
                value={password}
                type="password"
                onChange={(ev) => {setPassword(ev.target.value)}}
            />
            <Button onClick={() => login(tenantId, username, password, setLoginStatus, setActiveArea, successCallback)}>
                login
            </Button>
        </>
    );
}
