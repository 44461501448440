import * as React from 'react';

import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import LogoutIcon from '@mui/icons-material/Logout';

import Tenants from './main_views/Tenants';
import KnowledgeBases from './main_views/KnowledgeBases';
import WebsiteConfigs from './main_views/WebsiteConfigs';
import TestConversation from './main_views/TestConversation';
import Models from './main_views/Models';
import CustomPromptCollections from "./main_views/CustomPromptCollections";
import KnowledgeBaseFacts from "./main_views/KnowledgeBaseFacts";
import WebsiteConfigPrompts from "./main_views/WebsiteConfigPrompts";
import Login from "./main_views/Login";
import CustomPromptCollectionPrompts from "./main_views/CustomPromptCollectionPrompts";
import Users from "./main_views/Users";
import Tokens from "./main_views/Tokens";

import { MenuSidebar } from './MenuSidebar';
import Title from "./components/Title";

import {logout} from "../util/authentication";
import {sessionIsLoggedIn, sessionTenantId, sessionTenantName} from "../util/session_manager";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://maxomedia.ch/">
                Maxomedia
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const mdTheme = createTheme();

function DashboardContent() {
    const [open, setOpen] = React.useState(true);
    const [activeUser, setActiveUser] = React.useState(null);
    const [activeTenant, setActiveTenant] = React.useState(sessionTenantId());
    const [activeTenantName, setActiveTenantName] = React.useState(sessionTenantName());
    const [activeModel, setActiveModel] = React.useState(null);
    const [activeArea, setActiveArea] = React.useState("Test Conversation");
    const [activeKnowledgeBase, setActiveKnowledgeBase] = React.useState(null);
    const [activeWebsiteConfig, setActiveWebsiteConfig] = React.useState(null);
    const [activeCustomPromptCollection, setActiveCustomPromptCollection] = React.useState(null);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleLogoutClick = () => {
        logout(setActiveTenantName, setActiveArea);
    };

    let activeView = null;
    if (sessionIsLoggedIn()) {
        switch(activeArea) {
            case "Knowledge Bases":
                activeView = <KnowledgeBases activeTenant={activeTenant} setActiveArea={setActiveArea} setActiveKnowledgeBase={setActiveKnowledgeBase} />;
                break;
            case "Knowledge Base Facts":
                activeView = <KnowledgeBaseFacts activeTenant={activeTenant} activeKnowledgeBase={activeKnowledgeBase} />;
                break;
            case "Prompt Collections":
                activeView = <CustomPromptCollections activeTenant={activeTenant} setActiveArea={setActiveArea} setActiveCustomPromptCollection={setActiveCustomPromptCollection} />;
                break;
            case "Website Configs":
                activeView = <WebsiteConfigs activeTenant={activeTenant} setActiveArea={setActiveArea} setActiveWebsiteConfig={setActiveWebsiteConfig} />;
                break;
            case "Website Config Prompts":
                activeView = <WebsiteConfigPrompts activeTenant={activeTenant} activeWebsiteConfig={activeWebsiteConfig} />;
                break;
            case "Custom Prompt Collection Prompts":
                activeView = <CustomPromptCollectionPrompts activeTenant={activeTenant} activeCustomPromptCollection={activeCustomPromptCollection} />;
                break;
            case "Models":
                activeView = <Models activeTenant={activeTenant} activeModel={activeModel} setActiveModel={setActiveModel} />;
                break;
            case "Test Conversation":
                activeView = <TestConversation activeTenant={activeTenant} activeModel={activeModel} />
                break;
            case "User Admin":
                activeView = <Users activeUser={activeUser} setActiveUser={setActiveUser} />;
                break;
            case "Token Admin":
                activeView = <Tokens activeUser={activeUser} />;
                break;
            default:
                activeView = <Tenants activeTenant={activeTenant} setActiveTenant={setActiveTenant} setActiveTenantName={setActiveTenantName} />;
        }
    } else {
        if (activeArea !== "Login") {
            setActiveTenantName(null);
            setActiveArea("Login");
        }
        activeView = <Login
            setActiveArea={setActiveArea}
            successCallback={(tenantId, tenantName, modelId) => {
                setActiveTenant(tenantId);
                setActiveTenantName(tenantName);
                setActiveModel(modelId);
            }}
        />;
    }

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <IconButton onClick={() => {
                            setActiveArea("Tenants")
                        }}>
                            <img src="/assets/maxomedia.png" alt="Maxomedia Logo" width={"50px"} />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Maxo Chatbot Dashboard
                        </Typography>
                        <IconButton color="inherit">
                            <Badge badgeContent={0} color="secondary">
                                <NotificationsOffIcon />
                            </Badge>
                        </IconButton>
                        <IconButton color="inherit" aria-label="logout" onClick={handleLogoutClick}>
                            <LogoutIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        {MenuSidebar(activeArea, setActiveArea, activeTenant, activeTenantName, activeModel, activeUser)}
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            {/* Header */}
                            <Grid item xs={12}>
                                <img src="/assets/header02.png" alt="Header with Robot" width={"100%"}/>
                            </Grid>
                            {/* Active View */}
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    <Title>
                                        {activeArea}
                                        {(activeTenantName === null || activeArea === "Tenants") ? "" : ` - ${activeTenantName}`}
                                    </Title>
                                    {activeView}
                                </Paper>
                            </Grid>
                        </Grid>
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default function Dashboard() {
    return <DashboardContent />;
}
