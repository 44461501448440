import * as React from 'react';

import DataTable from "../components/DataTable";
import IconButton from "@mui/material/IconButton";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import EditIcon from "@mui/icons-material/Edit";


export default function CustomPromptCollections({activeTenant, setActiveArea, setActiveCustomPromptCollection}) {
    let titles = [
        "ID",
        "Name",
        "Description",
        "Language",
        "Actions",
    ];
    let actions = (custom_prompt_collection_id) => {
        return (
            <>
                <IconButton variant="contained" onClick={() => {
                    setActiveCustomPromptCollection(custom_prompt_collection_id);
                    setActiveArea("Custom Prompt Collection Prompts");
                }}>
                    <FactCheckIcon/>
                </IconButton>
                <IconButton variant="contained" onClick={() => {
                    alert('edit');
                }}>
                    <EditIcon/>
                </IconButton>
            </>
        )
    }
    let valueCallback = (title, record) => {
        switch (title) {
            case "ID":
                return record.id;
            case "Name":
                return record.name;
            case "Description":
                return record.description;
            case "Language":
                return record.language;
            case "Actions":
                return actions(record.id);
            default:
                return "...";
        }
    };

    return (
        <>
            <DataTable
                modelName={"Custom Prompt Collection"}
                tenantId={activeTenant}
                model={"custom_prompt_collection"}
                titles={titles}
                valueCallback={valueCallback}
                allowsCreate={true}
                simpleCreateFields={[
                    {
                        "name": "Name",
                        "key": "name",
                    },
                    {
                        "name": "Description",
                        "key": "description",
                    },
                    {
                        "name": "Language (de)",
                        "key": "language",
                    },
                ]}
            />
        </>
    );
}
