import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function SimpleNewEntryForm({modelName, fields, saveCallback}) {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [data, setData] = React.useState({});

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const saveNewRecord = () => {
        saveCallback(data);
        handleClose();
        setData({});
    }

    const cancelRecord = () => {
        handleClose();
        setData({});
    }

    const handleClose = () => {
        setDialogOpen(false);
    };

    const setValue = (key, value) => {
        let updatedData = {
            ...data,
        };
        updatedData[key] = value;
        setData(updatedData);
    }

    return (
        // div for layout, otherwise the button is full width
        <>
            <div>
                <Button variant="contained" onClick={handleClickOpen} sx={{ mt: 2, mb: 0 }}>
                    Create New {modelName}
                </Button>
            </div>
            <Dialog open={dialogOpen} onClose={handleClose}>
                <DialogTitle>Create New {modelName}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter all required data for the new {modelName} here:
                    </DialogContentText>
                    {(fields || []).map((field) => (
                        <TextField
                            key={field.key}
                            autoFocus
                            margin="dense"
                            id={field.key}
                            label={field.name}
                            type={field.type}
                            fullWidth
                            variant="standard"
                            value={data[field.key] || ''}
                            onChange={(ev) => {setValue(field.key, ev.target.value)}}
                        />
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelRecord}>Cancel</Button>
                    <Button variant="contained" onClick={saveNewRecord}>Create</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
