import React, {useState, useRef, useEffect} from 'react';

const AudioRecorder = ({onAudioRecorded}) => {
    const [isRecording, setIsRecording] = useState(false);
    const [recordedBlob, setRecordedBlob] = useState(null);
    const mediaRecorderRef = useRef(null);

    useEffect(() => {
        const requestMicrophonePermission = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const tempRecorder = new MediaRecorder(stream);
                tempRecorder.start();
                setTimeout(() => {
                    tempRecorder.stop();
                    stream.getTracks().forEach(track => track.stop());
                }, 1);
            } catch (err) {
                console.error("Error accessing microphone:", err);
            }
        };

        requestMicrophonePermission();
    }, []);

    const handleRecording = async () => {
        if (!isRecording) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({audio: true});
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.start();

                const audioChunks = [];
                mediaRecorderRef.current.addEventListener("dataavailable", (event) => {
                    audioChunks.push(event.data);
                });

                mediaRecorderRef.current.addEventListener("stop", () => {
                    const blob = new Blob(audioChunks, {type: 'audio/webm'});
                    setRecordedBlob(blob);
                    onAudioRecorded(blob);
                });

                setIsRecording(true);
            } catch (err) {
                console.error("Error accessing microphone:", err);
            }
        } else {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    return (
        <div>
            <button
                onMouseDown={handleRecording}
                onMouseUp={handleRecording}
            >
                {isRecording ? 'Recording...' : 'Record'}
            </button>
            {recordedBlob && (
                <div>
                    <audio controls src={URL.createObjectURL(recordedBlob)}/>
                    <p>Recorded audio size: {recordedBlob.size} bytes</p>
                </div>
            )}
        </div>
    );
};

export default AudioRecorder;
