import * as React from "react";
import axios from "axios";

import {Alert} from "@mui/material";

import config from "../Config";

import {clearSession, sessionAuthToken, storeSession} from "./session_manager";

export function getAuthHeader(){
    return {
        headers: {
            Authorization: `Bearer ${sessionAuthToken()}`
        }
    }
}

// TODO expand successCallback
export function login(tenantId, username, password, setLoginStatus, setActiveArea, successCallback) {
    const url = `${config.API_BASE_URL}/v1/auth/login`
    const data = {
        "tenant_id": tenantId,
        "username": username,
        "password": password,
    };
    axios
        .post(url, data)
        .then((response) => {
            const allowedToken = response.data.tokens.find(
                item => item.scopes.includes('manage') || item.scopes.includes('admin')
            );
            if (allowedToken) {
                setLoginStatus(<Alert severity="success">
                    Login successful! Redirecting...
                </Alert>)
                storeSession(
                    response.data.user_id,
                    response.data.tenant_id,
                    response.data.tenant_name,
                    username,
                    allowedToken.token,
                    !!response.data.is_admin
                );
                successCallback(response.data.tenant_id, response.data.tenant_name, response.data.newest_model_id);
                setActiveArea("Tenants")
            } else {
                setLoginStatus(<Alert severity="error">
                    Login failed, please try again!
                </Alert>)
            }
        })
        .catch((error) => {
            setLoginStatus(<Alert severity="error">
                Login failed, please try again!
            </Alert>)
        });
}

export function logout(setActiveTenantName, setActiveArea) {
    clearSession()
    setActiveTenantName(null);
    setActiveArea("Login");
}
