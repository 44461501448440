import Cookies from "js-cookie";

export function sessionIsLoggedIn() {
    return !!Cookies.get('auth_token');
}

export function sessionIsAdmin() {
    return Cookies.get('is_admin') === 'true';
}

export function sessionTenantId() {
    return Cookies.get('tenant_id') || '';
}

export function sessionTenantName() {
    return Cookies.get('tenant_name') || '';
}

export function sessionUsername() {
    return Cookies.get('username') || '';
}

export function sessionAuthToken() {
    return Cookies.get('auth_token');
}

export function storeSession(userId, tenantId, tenantName, username, authToken, isAdmin) {
    Cookies.set('user_id', userId, {expires: 1, secure: true, sameSite: 'strict'});
    Cookies.set('tenant_id', tenantId, {expires: 365});
    Cookies.set('tenant_name', tenantName, {expires: 365});
    Cookies.set('username', username, {expires: 365});
    Cookies.set('auth_token', authToken, {expires: 1, secure: true, sameSite: 'strict'});
    Cookies.set('is_admin', isAdmin ? 'true' : 'changing this will not give you permissions, only show empty menu items', {expires: 1, secure: true, sameSite: 'strict'});
}

export function clearSession() {
    Cookies.remove('auth_token');
    Cookies.remove('is_admin');
}
